.react-datepicker {
  font-family: 'Roboto'!important;
  border: none!important;
  color: black!important;
  width:100%;
}
.react-datepicker__month-container {
  height: 275px!important;
  border-radius: 0 0 0 3px!important;
  width: 100%!important;
  min-width: 291px!important;
}
.react-datepicker__header {
  border-bottom: none!important;
  padding-top: 20px!important;
}
.react-datepicker__month {
  margin: 0.5rem!important;
}
.react-datepicker__current-month {
  text-transform: capitalize;
  color: #434343!important;
}
.react-datepicker__day-name {
  text-transform: capitalize;
  color: #666666!important;
}
.react-datepicker__month-container, .react-datepicker__header {
  background-color: #F7E6DD!important;
}
.datepicker__day, .react-datepicker__time-name {
  color: #333333!important;
}
.react-datepicker__day--outside-month {
  visibility: hidden;
}
.react-datepicker__day--selected {
  background-color: #573B27!important;
}
.react-datepicker__navigation {
  top: 20px!important;
}
.react-datepicker__navigation--next {
  background: url('expandIcon.svg') no-repeat !important;
  transform: rotate(-90deg);
  width: 15px !important;
  height: 15px !important;
  border: none !important;
  background-size: 14px 14px!important;
}
.react-datepicker__navigation--previous {
  background: url('expandIcon.svg') no-repeat !important;
  transform: rotate(90deg);
  width: 15px !important;
  height: 15px !important;
  border: none !important;
  background-size: 14px 14px!important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
  background-color: #573B27!important;
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0!important;
}
.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 0!important;
}